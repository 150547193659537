import bg from '@/assets/purchaseOrder/bg.png'
import moment from "moment";
import {
  // 获取出库表格信息
  getTableList,
  orderReturnDetail,
  agentReturnOrderDetail, createReturnOrderInbound, agentReturnOrderInbound
} from "../api";

moment.locale("zh-cn");
export default {
  name: "channelWarehouse",
  components: {

  },
  data() {
    return {
      breadcrumbData: [
        {
          path: "/index",
          name: "index",
          title: "首页"
        },

        {
          path: "/salesReturn/authenticReturnEntry",
          name: "",
          title: "零售订单退货"
        }
      ],
      bg: bg,
      //表格搜索
      tableSearch: '',
      columns: [
        {
          title: '仓库',
          dataIndex: 'name',
          width: 200,
          ellipsis: true
        },
        {
          title: '质量等级',
          dataIndex: 'quvlitylv',
          width: 140,
        },
        {
          title: '正品退货数量',
          dataIndex: 'numberThis',
          width:160,
          scopedSlots: {
            customRender: "count"
          }
        },
        {
          title: '残次品退货数量',
          dataIndex: 'numberThis1',
          scopedSlots: {
            customRender: "count1"
          }
        },
      ],


      // 仅查看已选
      onlyShowChecked: false,
      sendObject: [],
      // 基本信息数据
      orderDetail: {
        orderHeader: {}
      },
      // 接口数据备份
      NeworderDetail: {
        NeworderHeader: {}
      },
      selectedRows: [],
      chukuTotal: 0,
      chukuVolume: 0,
      canciNum:0,
      daichuku: 0,
      // 全选list
      selectedRowKeys: [],
      selectCK: [],
      // 搜索用
      orgCode: '',
      searchLength: 0,
      model: '',
      pageLoadFlag: true,
      isLoading: false,
      buchais: false,
      message:''
    };

  },

  mounted() {
    // 销售出库列表查询
    // this.getOrderList();
    // 获取出库表格信息
    // this.getTable()
    // 点击退货查询以下接口
    this.getOrderList(this.$route.query.id)
  },
  methods: {
    // confirm
    confirm(id,index){
      this.orderDetail.orderHeader.itemList.splice(index,1)
    },
    // 销售出库列表查询
    getOrderList() {
      let requestDataA= {
        cisCode: this.$store.state.user.userInfo.customer.cisCode,
        orderId: this.$route.query.id
      }
      let requestDataB = {
        cisCode: this.$store.state.user.userInfo.customer.cisCode,
        returnOrderId: this.$route.query.id
      }
      let data =  this.$route.query.type=='th'? requestDataA:requestDataB
      let methods = this.$route.query.type=='th'? orderReturnDetail:agentReturnOrderDetail
      methods(data).then(res => {
        var realData = JSON.parse(res.data);
        if (realData.code == '1') {
          this.$message.warning(realData.msg);
        }
        var dataList = realData.data.itemList;
        this.orgCode = realData.data.orgCode;
        var newArray = [];
        for (let i = 0; i < dataList.length; i++) {
          this.daichuku = this.daichuku + Number(dataList[i].waitStockBQty-dataList[i].shippedBqty);
          let datas = {
            // 修改传参格式
            cisCode: this.$store.state.user.userInfo.customer.cisCode,
            warehouseType:70,
            orgId:realData.data.orgId,
          }
          this.pageLoadFlag = false;
          // this.getTable(data, i)
          // 查询商品库存
          newArray.push(getTableList(datas))
        }
        Promise.all(newArray).then(value => {
          for (let i = 0; i < value.length; i++) {
            // var newArrays = [];
            // 如果是gic预留  则可出库数量 == 预留数量
            // 非 gic预留仓库
            // 本次出库数量需要 小于 可出库数量 - 已出库数量
            var kechuku = 0;

            if (value[i].data) {
              var realValue = JSON.parse(value[i].data);
              realData.data.itemList[i].checkAll = false;
              realData.data.itemList[i].selectedRowKeys = [];
              realData.data.itemList[i].selectCK = []
              realData.data.itemList[i].bencichuku = 0;
              realData.data.itemList[i].cancituiKu = 0;
              for (let y = 0; y < realValue.data.length; y++) {
                realValue.data[y].index = i;
                realValue.data[y].price = realData.data.itemList[i].acknowledgedAmount;

                realValue.data[y].key = y;
                realValue.data[y].selectedTableRow = false;

                kechuku = kechuku + realValue.data[y].borderedQty;
                realValue.data[y].numberThis = 0;
                realValue.data[y].numberThis1 = 0;
                realValue.data[y].price = realData.data.itemList[i].bprice;
                realValue.data[y].quvlitylv = realData.data.itemList[i].invStatusName;
                realValue.data[y].bigQty = realData.data.itemList[i].waitStockBQty;
                realValue.data[y].invStatusTypeName = realData.data.itemList[i].invStatusTypeName
                realValue.data[y].shippedBqty = realData.data.itemList[i].shippedBqty
                realValue.data[y].volume = realData.data.itemList[i].volume
              }
              let tableListGroup = []
              realValue.data.forEach((item,index)=>{
                // 之前是只展示第一条 现在是全都显示
                tableListGroup.push(item)
                // if(index == 0){
                //     tableListGroup.push(item)
                // }
                return  tableListGroup
              })
              realData.data.itemList[i].searchLength = tableListGroup.length;
              realData.data.itemList[i].tableList = tableListGroup;
              realData.data.itemList[i].kechuku = kechuku;
            }
          }
          this.orderDetail.orderHeader = realData.data;
          this.NeworderDetail.NeworderHeader = JSON.parse(JSON.stringify(realData.data));

          this.$forceUpdate();
        })
      }).finally(() => {
        this.pageLoadFlag = false;
      })
    },


    // 搜索仓库
    tableSearchClick(item, index) {
      var newarray = [];
      for (let el of this.orderDetail.orderHeader.itemList[index].tableList) {
        var indexok = item.name.indexOf(this.model)
        if (indexok != -1) {
          newarray.push(el);
        }
      }

      let tota = 0
      for (var i = 0; i < newarray.length; i++) {
        tota += Number(newarray[i].bigQty)
      }

      this.orderDetail.orderHeader.itemList[index].kechuku = tota;
      this.orderDetail.orderHeader.itemList[index].searchLength = newarray.length;
      this.orderDetail.orderHeader.itemList[index].tableList = newarray;
    },

    // 清空选择
    clearChose(index) {
      this.orderDetail.orderHeader.itemList[index].tableList.forEach(item=>{
        item.numberThis = 0
        item.numberThis1 = 0
      })
      this.orderDetail.orderHeader.itemList[index].bencichuku = 0
      this.orderDetail.orderHeader.itemList[index].cancituiKu = 0
      let goodsNum = 0,
          goodsVol = 0;
      for (let i = 0; i < this.orderDetail.orderHeader.itemList.length; i++) {
        goodsNum += (Number(this.orderDetail.orderHeader.itemList[i].bencichuku)+Number(this.orderDetail.orderHeader.itemList[i].cancituiKu))
        goodsVol += (Number(this.orderDetail.orderHeader.itemList[i].volume)*(Number(this.orderDetail.orderHeader.itemList[i].bencichuku)+Number(this.orderDetail.orderHeader.itemList[i].cancituiKu)))
      }
      this.chukuTotal = goodsNum;
      this.chukuVolume = goodsVol;
    },


    //出库数量改变
    inputNumberChange(row,index) {
      var realNumber = this.orderDetail.orderHeader.itemList[index];
      var realData = this.orderDetail.orderHeader.itemList;
      var chukuNumber = 0;
      for (let i = 0; i < realNumber.tableList.length; i++) {
        chukuNumber += Number(realNumber.tableList[i].numberThis);
      }
      this.orderDetail.orderHeader.itemList[index].bencichuku = chukuNumber;
      var chukuzong = 0,
        chukutiji = 0
      for (let y = 0; y < realData.length; y++) {
        if (realData[y].tableList.length) {
          for (let z = 0; z < realData[y].tableList.length; z++) {
            chukuzong += Number(realData[y].tableList[z].numberThis1) + Number(realData[y].tableList[z].numberThis);
            chukutiji += Number(realData[y].tableList[z].numberThis1 * realData[y].tableList[z].volume) +  Number(realData[y].tableList[z].numberThis * realData[y].tableList[z].volume);
          }
        }
      }
      this.chukuTotal = chukuzong;
      this.chukuVolume = this.$util.getFloat(chukutiji, 3);
    },
    inputNumberChange1(row,index) {
      let realNumber = this.orderDetail.orderHeader.itemList[index];
      let realData = this.orderDetail.orderHeader.itemList;
      let chukuNumber = 0;
      for (let i = 0; i < realNumber.tableList.length; i++) {
        chukuNumber += Number(realNumber.tableList[i].numberThis1);
      }
      this.orderDetail.orderHeader.itemList[index].cancituiKu = chukuNumber;
      var chukuzong = 0,
        chukutiji = 0
      for (let y = 0; y < realData.length; y++) {
        if (realData[y].tableList.length) {
          for (let z = 0; z < realData[y].tableList.length; z++) {
            chukuzong += Number(realData[y].tableList[z].numberThis1) + Number(realData[y].tableList[z].numberThis);
            chukutiji += Number(realData[y].tableList[z].numberThis1 * realData[y].tableList[z].volume) +  Number(realData[y].tableList[z].numberThis * realData[y].tableList[z].volume);
          }
        }
      }
      this.chukuTotal = chukuzong;
      this.chukuVolume = this.$util.getFloat(chukutiji, 3);
    },
    orderSubmitRK(){
      this.orderDetail.orderHeader.itemList.forEach(item=>{
        item.chooseNum = 0
        item.ckArr=[]
        item.tableList.forEach(it=>{
          if( it.numberThis>0 || it.numberThis1>0){
            item.ckArr.push(it)
          }
          item.chooseNum += Number(it.numberThis)
        })
      })
      let arr = []
      for (let i = 0;i<this.orderDetail.orderHeader.itemList.length;i++){
        // if(this.orderDetail.orderHeader.itemList[i].bencichuku<=0){
        //   this.$message.warning(`第${i+1}个商品的仓库不能为空`)
        //   return
        // }
        if(this.$route.query.type=='th' && (this.orderDetail.orderHeader.itemList[i].bencichuku+this.orderDetail.orderHeader.itemList[i].cancituiKu)>this.orderDetail.orderHeader.itemList[i].waitStockBQty){
          this.$message.warning(`第${i+1}个商品退货数量不可大于可退货数量`)
          return
        }
        if(this.$route.query.type=='rk' &&  (this.orderDetail.orderHeader.itemList[i].bencichuku+this.orderDetail.orderHeader.itemList[i].cancituiKu)>this.orderDetail.orderHeader.itemList[i].receivedQtyNew){
          this.$message.warning(`第${i+1}个商品退货数量不可大于退货数量`)
          return
        }
        if (this.orderDetail.orderHeader.itemList[i].ckArr.length>1){
          this.$message.warning(`第${i+1}个商品的只能选择一个仓库进行退货,请重新选择`)
          return
        }
        arr.push(...this.orderDetail.orderHeader.itemList[i].ckArr.map(it => {
          if (it.numberThis>0 || it.numberThis1>0 ) {
              return {
                // ...it,
                model:this.orderDetail.orderHeader.itemList[i].model,
                productCode:this.orderDetail.orderHeader.itemList[i].productCode,
                materialCode:this.orderDetail.orderHeader.itemList[i].materialCode,
                borderedQty:it.numberThis,
                bprice:this.orderDetail.orderHeader.itemList[i].bprice,
                amount:this.orderDetail.orderHeader.itemList[i].bprice*(Number(it.numberThis)+Number(it.numberThis1)),
                invStatusId:this.orderDetail.orderHeader.itemList[i].invStatusId,
                invStatusType:this.orderDetail.orderHeader.itemList[i].invStatusName,
                warehouseId:it.cId,
                ordinaryQty:it.numberThis,
                imperfectQty:it.numberThis1,

              }
            }
          })
        )
      }
      if(arr.length <= 0 ){
        this.$message.warning(`请至少选择一个商品进行退货`)
        return
      }
      let data = {
        returnOrderId: this.$route.query.id,
        cisCode: this.$store.state.user.userInfo.customer.cisCode,
        returnItemList: arr,
      }
      this.isLoading = true
      agentReturnOrderInbound(data).then(res=>{
        this.isLoading = false
        let realData = JSON.parse(res.data);
        if (realData.code == '1') {
          this.$message.warning(realData.msg);
        } else if (realData.code == '0') {
          this.$message.success('入库成功')
          this.$router.push({
            path: "/salesReturn/success",
            query: {
              b2bOrderCode: data.msg,
              source:'xiaoshou'
            }
          });
        }
      })
    },
    // 提交
    orderSubmit(){
      this.orderDetail.orderHeader.itemList.forEach(item=>{
        item.chooseNum = 0
        item.ckArr=[]
        item.tableList.forEach(it=>{
          if( it.numberThis>0 || it.numberThis1>0){
            item.ckArr.push(it)
          }
          item.chooseNum += Number(it.numberThis)
        })
      })
      let arr = []
      for (let i = 0;i<this.orderDetail.orderHeader.itemList.length;i++){
        if(this.$route.query.type=='th' && (this.orderDetail.orderHeader.itemList[i].bencichuku+this.orderDetail.orderHeader.itemList[i].cancituiKu)>this.orderDetail.orderHeader.itemList[i].waitStockBQty){
          this.$message.warning(`第${i+1}个商品退货数量不可大于可退货数量`)
          return
        }
        if(this.$route.query.type=='rk' &&  (this.orderDetail.orderHeader.itemList[i].bencichuku+this.orderDetail.orderHeader.itemList[i].cancituiKu)>this.orderDetail.orderHeader.itemList[i].receivedQtyNew){
          this.$message.warning(`第${i+1}个商品退货数量不可大于退货数量`)
          return
        }
        if (this.orderDetail.orderHeader.itemList[i].ckArr.length>1){
          this.$message.warning(`第${i+1}个商品的只能选择一个仓库进行退货,请重新选择`)
          return
        }
        arr.push(...this.orderDetail.orderHeader.itemList[i].ckArr.map(it => {
            if (it.numberThis >0 || it.numberThis1>0 ) {
              return {
                // ...it,
                model:this.orderDetail.orderHeader.itemList[i].model,
                productCode:this.orderDetail.orderHeader.itemList[i].productCode,
                borderedQty:it.numberThis,
                bprice:this.orderDetail.orderHeader.itemList[i].bprice,
                amount:this.orderDetail.orderHeader.itemList[i].bprice*(it.numberThis+it.numberThis1),
                invStatusId:this.orderDetail.orderHeader.itemList[i].invStatusId,
                invStatusType:this.orderDetail.orderHeader.itemList[i].invStatusName,
                warehouseId:it.cId,
                imperfectQty:it.numberThis1,
              }
            }
          })
        )
      }
      if(arr.length <= 0 ){
        this.$message.warning(`请至少选择一个商品进行退货`)
        return
      }
      let data = {
        returnOrder:{
          documentNum:this.orderDetail.orderHeader.documentNum,
          message:this.message,
          amount:'',
          returnItemList:arr,
          isImperfect:false,
        }
      }
      this.isLoading = true
      createReturnOrderInbound(data).then(res=>{
        this.isLoading = false
        let realData = JSON.parse(res.data);
        if (realData.code == '1') {
          this.$message.warning(realData.msg);
        } else if (realData.code == '0') {
          this.$message.success('退货成功')
          this.$router.push({
            path: "/salesReturn/success",
            query: {
              b2bOrderCode: realData.msg?realData.msg:'',
              source:'xiaoshou'
            }
          });
        }
      })
    },
    // 取消
    orderCancle() {
      localStorage.setItem('XTW', new Date());
      window.opener.location.reload();
      window.close()
    },
    //
    formatDate(date) {
      return moment(date).format('YYYY-MM-DD HH:mm:ss');
    },
  },
};